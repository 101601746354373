import React from 'react';
import {
    Switch,
    Route,
    HashRouter
  } from "react-router-dom";
import App from './page/home/App';
import Hooks from './page/hooks/Hooks';
import TrafficLight from './page/trafficLight/TrafficLight.jsx';
import Three from './page/three';
import Wedding from './page/wedding';


function RouteApp() {
    return (
      <HashRouter>
        <Switch>
          {/* If the current URL is /about, this route is rendered
              while the rest are ignored */}
          <Route path="/hooks">
            <Hooks name='fffff'/>
          </Route>
          <Route path="/trafficLight">
            <TrafficLight />
          </Route>
          <Route path="/three">
            <Three />
          </Route>
          <Route path="/wedding">
            <Wedding />
          </Route>
          {/* If none of the previous routes render anything,
              this route acts as a fallback.
  
              Important: A route with path="/" will *always* match
              the URL because all URLs begin with a /. So that's
              why we put this one last of all */}
          <Route path="/">
            <App />
          </Route>
        </Switch>
      </HashRouter>
    );
  }

export default RouteApp;
