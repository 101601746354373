import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a className="App-logo" href="#/wedding">
          <img src={logo}  alt="logo" />
        </a>
        <div className="App-footer">
          <p>
            早睡早起，morning鸭
          </p>
          <footer>
        备案号：<a href="http://www.beian.miit.gov.cn/">粤ICP备17096574号</a>
        </footer>
        </div>
      </header>
      
    </div>
  );
}

export default App;
