import React, { Component } from "react";
import './TrafficLight.css';
import Button from '../hooks/Hooks'
function sleep(timeout){
  return new Promise(resolve =>{
    setTimeout(resolve, timeout);
  })
}
export default class TrafficLight extends Component {
  constructor(props) {
    super()
    this.state = {
      currentColor:'',
      isFlash: false
    };
  }
  static defaultProps = {
    lights:[
      {color: 'red', duration: 10000, twinkleDuration: 5000},
      {color: 'green', duration: 10000, twinkleDuration: 5000},
      {color: 'yellow', duration: 2000},
    ]
  }
  componentDidMount(){
    this.run()
  }

  run = async() =>{
    for (const lightConfig of this.props.lights) {
      this.setState({
        currentColor:lightConfig.color
      })
      const sleepTime = lightConfig.twinkleDuration ? (lightConfig.duration - lightConfig.twinkleDuration) : 0
      await sleep(sleepTime)
      if(lightConfig.twinkleDuration > 0){
        this.setState({
          isFlash: true
        })
      }
      await sleep(lightConfig.duration || 0)
      this.setState({
        isFlash: false
      })
    }
    this.run()
  }

  render() {
    const { currentColor,isFlash } = this.state
    return (
      <div className="traffic-box">
        <div className={`traffic-item ${isFlash?'traffic-item-twinkle':''}`} style={{backgroundColor:currentColor}}></div>
        <Button name="ddd"/>
      </div>
    );
  }
}