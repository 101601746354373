import React, { useState, useEffect, useCallback, useLayoutEffect } from "react";

export default function Button({name})  {
  const  [buttonText, setButtonText] =  useState("Click me,   please");
  // const  [buttonText2, setButtonText2] =  useState('333');

  useEffect(() => {
    console.log('222')
  },[buttonText]);
  useLayoutEffect(() => {
    console.log('244422')
  },[buttonText]);
  useCallback(
    () => {
      console.log('buttonText',buttonText)
    },
    [buttonText],
  );
  function handleClick()  {
    return setButtonText("Thanks, been clicked!");
  }
  function handleChange(e){
    console.log('e',e.target.value)
    setButtonText(e.target.value)
  }
  return ( <React.Fragment>
    <button onClick={handleClick}>{buttonText}{name}</button>
    <input onChange={handleChange}></input>
  </React.Fragment>);
}